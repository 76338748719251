<template>
  <div class="page-container">
    <div class="map-box" ref="map"></div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import { MAP_PK } from "@/common/constants";
import STYLE from "@/common/mapfiles/drawBottomStyle.json";

const mapImagesUrl = {
  // 养殖
  yangzhi: "/imgs/mapImage/farm/养殖.png",
  // 种植
  zhongzhi: "/imgs/mapImage/farm/种植.png",
};

export default {
  data() {
    return {
      // 图标加载完成标记
      imageLoaded: false,
    };
  },
  methods: {
    // 初始化地图
    initMap() {
      this.map = new mapboxgl.Map({
        container: this.$refs.map,
        accessToken: MAP_PK,
        style: STYLE,
        center: [106.54940292957053, 29.561248875005035],
        zoom: 6,
      });
      this.map.on("load", () => {
        //   基地图标加载
        this.addMapImage();
        //   画基地
        this.getFarms();
      });
    },
    //   基地图标加载
    addMapImage() {
      let requestCount = 0;
      let readyCount = 0;
      for (let key in mapImagesUrl) {
        requestCount++;
        this.map.loadImage(mapImagesUrl[key], (e, image) => {
          readyCount++;
          if (e) return;
          this.map.addImage(key, image, {
            pixelRatio: 1,
          });
          if (readyCount == requestCount) this.imageLoaded = true;
        });
      }
    },
    // 查询养殖场列表 为了演示 以后用特定接口
    getFarms() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 9999,
      }).then((res) => {
        this.getGeojson(res.list);
      });
    },
    // 组装地理数据
    getGeojson(list = []) {
      let features = [];
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let center;
        try {
          center = JSON.parse(item.thirdLongLat).gcj02;
        } catch {
          continue;
        }
        features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: center,
          },
          properties: {
            image:
              item.siteType == 1
                ? "zhongzhi"
                : item.siteType == 2
                ? "yangzhi"
                : "",
            text: item.name,
          },
        });
      }
      let geojson = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features,
        },
      };
      let id = "farm";
      if (this.map.getSource(id)) {
        this.map.getSource(id).setData({
          type: "FeatureCollection",
          features,
        });
      } else {
        this.map.addSource(id, geojson);
      }
      let addLay = function () {
        this.map.addLayer({
          id,
          source: id,
          type: "symbol",
          layout: {
            "icon-image": ["get", "image"],
            "icon-ignore-placement": true,
            "icon-offset":[0,-20],
            "text-field": ["get", "text"],
            "text-ignore-placement": true,
            "text-offset":[0,0.1],
          },
        });
      }.bind(this);
      if (!this.map.getLayer(id) && this.imageLoaded) {
        addLay();
      } else if (!this.map.getLayer(id)) {
        let unwatch = this.$watch("imageLoaded", () => {
          addLay();
          unwatch();
        });
      }
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>